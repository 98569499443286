import { Controller } from "stimulus";
import { useDataGrid } from 'mixins/useDataGrid';

export default class extends Controller {
  static targets = [ 'grid' ]

  static values = {
    rowData: Array,
  }

  connect() {
    useDataGrid(this);

    this.gridOptions = Object.assign(this.gridBaseOptions(), {
      columnDefs: [
        { field: 'status', headerName: 'Status', flex: 1 },
        { field: 'year', headerName: 'Year', flex: 1 },
        { field: 'type', headerName: 'Type', flex: 1 },
        {
          field: 'due_date', headerName: 'Due Date', flex: 1,
          valueFormatter: (params) => {
            const date = new Date(params.value)
            return date.toLocaleDateString()
          },
        },
        { field: 'top_n_for_review', headerName: 'Top N for Review', flex: 1 },
        { field: 'top_n_for_vote', headerName: 'Top N for Vote', flex: 1 },
        { field: 'actions', headerName: '', sortable: false, filter: false, resizable: true, minWidth: 400,
          cellClass: 'text-right border-0',
          cellRenderer: (params) => {
            return params.data && `
              <a
                class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-accent focus:outline-none focus:ring-2 focus:ring-offset-2"
                href="/drills/${params.data.id}">
                VIEW
              </a>
              <a
                class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-accent focus:outline-none focus:ring-2 focus:ring-offset-2"
                href="/drills/${params.data.id}/edit">
                EDIT
              </a>
              <a
                class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-accent focus:outline-none focus:ring-2 focus:ring-offset-2"
                href="/drills/${params.data.id}"
                data-method="delete"
                data-confirm="Are you sure?">
                DELETE
              </a>
              `;
          }
        },
      ],
    });

    this.gridConnect();
  }

  disconnect() {
    this.gridDisconnect();
  }

}
