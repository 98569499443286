import { Controller } from "stimulus";
import { useDataGrid } from 'mixins/useDataGrid';

export default class extends Controller {
  static targets = [ 'grid' ]

  static values = {
    rowData: Array,
  }

  connect() {
    useDataGrid(this);

    this.gridOptions = Object.assign(this.gridBaseOptions(), {
      columnDefs: [
        { field: 'organization_oban_name', headerName: 'OBAN', width: 120 },
        { field: 'organization_name', headerName: 'Organization', flex: 1 },
        { field: 'amount', headerName: 'Amount', flex: 1, cellClass: 'text-center', valueFormatter: this.currencyFormatter },
        { field: 'funded_amount', headerName: 'Funded Amount', flex: 1, cellClass: 'text-center', valueFormatter: this.currencyFormatter },
        { field: 'unfunded_amount', headerName: 'Unfunded Amount', flex: 1, cellClass: 'text-center', valueFormatter: this.currencyFormatter },
        { field: 'vote_progress', headerName: 'Vote Progress', flex: 1 },
        { field: 'actions', headerName: '', sortable: false, filter: false, resizable: true, minWidth: 300,
          cellClass: 'text-right border-0',
          cellRenderer: (params) => {
            return params.data && `
              <a
                class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-accent focus:outline-none focus:ring-2 focus:ring-offset-2"
                href="/drills/${params.data.drill_id}/organizations/${params.data.organization_id}/requirement_items">
                LIST
              </a>
              <a
                class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-accent focus:outline-none focus:ring-2 focus:ring-offset-2"
                target="_blank"
                href="/drills/${params.data.drill_id}/organizations/${params.data.organization_id}/slides">
                SLIDES
              </a>
              <a
                class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-accent focus:outline-none focus:ring-2 focus:ring-offset-2"
                target="_blank"
                href="/drills/${params.data.drill_id}/organizations/${params.data.organization_id}/votes">
                VOTE
              </a>
              `;
          }
        },
      ],
    });

    this.gridConnect();
  }

  disconnect() {
    this.gridDisconnect();
  }

  currencyFormatter = (params) => {
    return '$' + Math.floor(params.value)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };

}
