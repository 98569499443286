// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import NestedForm from "stimulus-rails-nested-form"
import { Tabs } from "tailwindcss-stimulus-components"

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
const componentContext = require.context("../../components/", true, /(.*)\/.+_controller\.js$/);

application.register("nested-form", NestedForm)
application.register('tabs', Tabs)
application.load(
  definitionsFromContext(context).concat(
    definitionsFromContext(componentContext)
  )
)
