import { Controller } from "stimulus";
import { useDataGrid } from 'mixins/useDataGrid';

export default class extends Controller {
  static targets = [ 'grid' ]

  static values = {
    rowData: Array,
  }

  connect() {
    useDataGrid(this);

    this.gridOptions = Object.assign(this.gridBaseOptions(), {
      columnDefs: [
        { field: 'first_name', headerName: 'First Name', width: 150 },
        { field: 'last_name', headerName: 'Last Name', width: 150 },
        { field: 'email', headerName: 'Email', width: 150, flex: 1 },
        { field: 'actions', headerName: '', sortable: false, filter: false, resizable: false, minWidth: 170,
          cellClass: 'text-right border-0',
          cellRenderer: (params) => {
            return params.data && `
              <a
                class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-accent focus:outline-none focus:ring-2 focus:ring-offset-2"
                href="/users/${params.data.id}/edit">
                EDIT
              </a>
              <a
                class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-accent focus:outline-none focus:ring-2 focus:ring-offset-2"
                href="/users/${params.data.id}"
                data-method="delete"
                data-confirm="Are you sure?">
                DELETE
              </a>
            `;
          }
        },
      ],
    });

    this.gridConnect();
  }

  disconnect() {
    this.gridDisconnect();
  }

}
