import { Controller } from 'stimulus'
import { toggle, leave } from 'el-transition';

export default class extends Controller {
  static targets = [ 'menu' ]

  connect() {
    this.toggleClass = this.data.get('class') || 'hidden'
  }

  toggle() {
    toggle(this.menuTarget, 'dropdown')
  }

  hide(event) {
    if (this.element.contains(event.target) === false && !this.menuTarget.classList.contains(this.toggleClass)) {
      leave(this.menuTarget, 'dropdown')
    }
  }
}
