import { Grid } from 'ag-grid-community';
import 'ag-grid-enterprise';

export const useDataGrid = controller => {
  if (!controller.gridTarget) {
    throw 'grid is not set as a target';
  }

  if (!controller.rowDataValue) {
    throw 'rowData is not set as a value';
  }

  Object.assign(controller, {
    gridBaseOptions() {
      return {
        sideBar: {
          toolPanels: [
            {
              id: 'columns',
              labelDefault: 'Columns',
              labelKey: 'columns',
              iconKey: 'columns',
              toolPanel: 'agColumnsToolPanel',
            },
            {
              id: 'filters',
              labelDefault: 'Filters',
              labelKey: 'filters',
              iconKey: 'filter',
              toolPanel: 'agFiltersToolPanel',
            }
          ],
        },
        statusBar: {
          statusPanels: [
            {
              statusPanel: 'agTotalAndFilteredRowCountComponent',
              align: 'left',
            },
            {
              statusPanel: 'agSelectedRowCountComponent',
              align: 'left',
            },
          ]
        },
        defaultColDef: {
          sortable: true, filter: true, resizable: true,
          enableValue: true, enableRowGroup: true, enablePivot: true,
        },
        rowData: this.rowDataValue,
        onGridReady: () => {
          this.gridTarget.classList.remove("opacity-0");
          this.gridTarget.classList.add("opacity-100");
        },
      }
    },
    gridConnect() {
      this.gridOptions ||= this.gridBaseOptions();
      new Grid(this.gridTarget, this.gridOptions);
    },
    gridDisconnect() {
      this.gridTarget.classList.add('opacity-0');
      this.gridTarget.classList.remove('opacity-100');
      this.gridOptions.api.destroy();
    }
  });
};