import { Controller } from "stimulus";
import { useDataGrid } from 'mixins/useDataGrid';

export default class extends Controller {
  static targets = [ 'grid' ]

  static values = {
    rowData: Array,
  }

  connect() {
    useDataGrid(this);

    this.gridOptions = Object.assign(this.gridBaseOptions(), {
      // General
      columnDefs: [
        {
          field: 'owner_number', headerName: 'Number', width: 120, cellClass: 'text-center', sortable: false, 
          filter: false, enableCellChangeFlash: true, cellRenderer: 'agGroupCellRenderer'
        },
        {
          field: 'title', headerName: 'Title', minWidth: 250, flex: 1,
          cellClass: '',
          cellRenderer: (params) => {
            return params.data && `
              <a
                class="underline"
                href="/organizations/${params.data.organization_id}/requirement_items/${params.data.id}">
                ${params.data.title}
              </a>
            `;
          }
        },
        { field: 'priority_level', headerName: 'P. Level', width: 120, cellClass: 'text-center' },
        { field: 'organization_oban_name', headerName: 'OBAN', width: 120 },
        { field: 'organization_name', headerName: 'Organization', width: 250 },
        { field: 'amount', headerName: 'Total Cost', width: 140, cellClass: 'text-center', valueFormatter: this.currencyFormatter },
        { field: 'rolling_cost', headerName: 'Rolling Cost', width: 140, cellClass: 'text-center', valueFormatter: this.currencyFormatter,
          cellStyle: (params) => {
            const ratio = (params.rowIndex + 1) / params.api.getDisplayedRowCount();
            const opacityMin = 0.1;
            const opacity = opacityMin + (1 - opacityMin) * ratio;
            return { 'background-color': `rgba(110, 231, 183, ${opacity})` };
          },
        },
      ],
      getRowNodeId: (data) => data.id,
      rowClass: 'border-0 border-b-2 border-gray-50',
      suppressRowClickSelection: true,
      // Rolling Cost
      onModelUpdated: (event) => {
        event.api.forEachNodeAfterFilterAndSort((node, index) => {
          const previousNode = event.api.getDisplayedRowAtIndex(index - 1);
          node.setDataValue('rolling_cost', (previousNode?.data?.rolling_cost || 0) + (node?.data?.amount || 0));
        });
      },
      // Roll Ups
      masterDetail: true,
      isRowMaster: (dataItem) => {
        return dataItem ? dataItem.requirement_items.length > 0 : false;
      },
      detailCellRendererParams: {
        detailGridOptions: {
          columnDefs: [
            {
              field: 'title', headerName: 'Title', minWidth: 250, flex: 1,
              cellClass: '',
              cellRenderer: (params) => {
                return params.data && `
                  <a
                    class="underline"
                    href="/organizations/${params.data.organization_id}/requirement_items/${params.data.id}">
                    ${params.data.title}
                  </a>
                `;
              }
            },
            { field: 'amount', headerName: 'Total Cost', width: 140, cellClass: 'text-center', valueFormatter: this.currencyFormatter },
          ],
        },
        getDetailRowData: function (params) {
          params.successCallback(params.data.requirement_items);
        },
      },
    });

    this.gridConnect();
  }

  disconnect() {
    this.gridDisconnect();
    this.subscription.unsubscribe();
  }

  currencyFormatter = (params) => {
    return '$' + Math.floor(params.value)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };

}
