import { Controller } from "stimulus";
import { useDataGrid } from 'mixins/useDataGrid';

export default class extends Controller {
  static targets = [ 'grid' ]

  static values = {
    rowData: Array,
  }

  connect() {
    useDataGrid(this);

    this.gridOptions = Object.assign(this.gridBaseOptions(), {
      defaultColDef: {
        sortable: true, filter: true, resizable: true,
        enableValue: false, enableRowGroup: false, enablePivot: false,
      },
      columnDefs: [
        { field: 'oban_name', headerName: 'OBAN', width: 150 },
        { field: 'level', headerName: 'Level', width: 150 },
        { field: 'actions', headerName: '', sortable: false, filter: false, resizable: false, minWidth: 170,
          cellClass: 'text-right border-0',
          cellRenderer: (params) => {
            return params.data && `
              <a
                class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-accent focus:outline-none focus:ring-2 focus:ring-offset-2"
                href="/organizations/${params.data.id}/requirement_list">
                REQUIREMENTS
              </a>
            `;
          }
        },
      ],
      treeData: true,
      groupDefaultExpanded: 1,
      getDataPath: function (data) {
        return data.path_by_name;
      },
      autoGroupColumnDef: {
        headerName: 'Name',
        flex: 1,
        cellRendererParams: {
          suppressCount: true
        },
      },
    });

    this.gridConnect();
  }

  disconnect() {
    this.gridDisconnect();
  }

}
