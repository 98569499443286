import { Controller } from "stimulus";
import { useDataGrid } from 'mixins/useDataGrid';
import consumer from 'channels/consumer';

export default class extends Controller {
  static targets = [ 'grid', 'activeCollaborators' ]

  static values = {
    id: String,
    rowData: Array,
    ownerType: String,
    organizationLevel: String,
    topN: Number,
  }

  connect() {
    useDataGrid(this);

    this.subscription = consumer.subscriptions.create(
      {
        channel: "RequirementListChannel",
        id: this.idValue
      },
      {
        received: (payload) => {
          if (payload.numbers_updated)
            this.updateGridNumbers(payload.numbers_updated);
          if (payload.rolled_up || payload.broke_out)
            location.reload();
        }
      }
    );

    this.gridOptions = Object.assign(this.gridBaseOptions(), {
      // General
      columnDefs: [
        { pinned: 'left', width: 50,
          rowDrag: true,
          sortable: false, filter: false, resizable: false, suppressMenu: true, suppressColumnsToolPanel: true,
        },
        { pinned: 'left', width: 70,
          headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection:true,
          sortable: false, filter: false, resizable: false, suppressMenu: true, suppressColumnsToolPanel: true,
        },
        {
          field: 'number', headerName: 'Number', width: 120, cellClass: 'text-center', sortable: false, 
          filter: false, enableCellChangeFlash: true, cellRenderer: 'agGroupCellRenderer'
        },
        { field: 'owner_number', headerName: 'Owner Number', width: 160, cellClass: 'text-center bg-blue-50', hide: !this.shouldShowOwnerNumber() },
        { field: 'weighted_score', headerName: 'Score', width: 120, hide: !this.shouldShowWeightedScore() },
        {
          field: 'title', headerName: 'Title', minWidth: 250, flex: 1,
          cellClass: '',
          cellRenderer: (params) => {
            return params.data && `
              <a
                class="underline"
                href="/organizations/${params.data.organization_id}/requirement_items/${params.data.id}">
                ${params.data.title}
              </a>
            `;
          }
        },
        { field: 'fiscal_year', headerName: 'Fiscal Year', width: 120, cellClass: 'text-center' },
        { field: 'priority_level', headerName: 'P. Level', width: 120, cellClass: 'text-center' },
        { field: 'organization_oban_name', headerName: 'OBAN', width: 120 },
        { field: 'organization_level_and_name', headerName: 'Organization', width: 250 },
        { field: 'amount', headerName: 'Total Cost', width: 140, cellClass: 'text-center', valueFormatter: this.currencyFormatter },
        { field: 'rolling_cost', headerName: 'Rolling Cost', width: 140, cellClass: 'text-center', valueFormatter: this.currencyFormatter,
          cellStyle: (params) => {
            const ratio = (params.rowIndex + 1) / params.api.getDisplayedRowCount();
            const opacityMin = 0.1;
            const opacity = opacityMin + (1 - opacityMin) * ratio;
            return { 'background-color': `rgba(110, 231, 183, ${opacity})` };
          },
        },
      ],
      getRowNodeId: (data) => data.id,
      rowClass: 'border-0 border-b-2 border-gray-50',
      rowClassRules: {
        'border-accent': (params) => this.shouldShowTopN(params) && params.node.rowIndex+1 === this.topNValue,
        'border-white bg-gray-100': (params) => this.shouldShowTopN(params) && params.node.rowIndex+1 > this.topNValue,
      },
      suppressRowClickSelection: true,
      // Drag and Drop
      animateRows: true,
      rowDragManaged: true,
      rowSelection: 'multiple',
      onRowDragEnd: (event) => {
        const data = [];
        event.api.forEachNode((node, index) => {
          const number = index + 1;
          if (node.data.number !== number) {
            node.setDataValue('number', number);
            data.push({ id: node.data.id, number: number });
          }
        });
        this.subscription.perform("update_numbers", { data });
      },
      // Rolling Cost
      onModelUpdated: (event) => {
        event.api.forEachNodeAfterFilterAndSort((node, index) => {
          const previousNode = event.api.getDisplayedRowAtIndex(index - 1);
          node.setDataValue('rolling_cost', (previousNode?.data?.rolling_cost || 0) + (node?.data?.amount || 0));
        });
      },
      // Context Menu
      popupParent: document.querySelector('body'),
      getContextMenuItems: (params) => {
        const items = [];

        if (this.shouldShowRollUp(params)) {
          if (params.api.getSelectedNodes().length > 1) {
            items.push({
              name: 'Roll Up',
              action: () => this.rollUp(params),
            });
            items.push('separator');
          }
  
          if (params.api.getSelectedNodes().length > 0) {
            items.push({
              name: 'Break Out',
              action: () => this.breakOut(params),
            });
            items.push('separator');
          }
        }

        return items.concat([
          'copy',
          'copyWithHeaders',
          'export',
        ]);
      },
      // Roll Ups
      masterDetail: true,
      isRowMaster: (dataItem) => {
        return dataItem ? dataItem.requirement_items.length > 0 : false;
      },
      detailCellRendererParams: {
        detailGridOptions: {
          columnDefs: [
            {
              field: 'title', headerName: 'Title', minWidth: 250, flex: 1,
              cellClass: '',
              cellRenderer: (params) => {
                return params.data && `
                  <a
                    class="underline"
                    href="/organizations/${params.data.organization_id}/requirement_items/${params.data.id}">
                    ${params.data.title}
                  </a>
                `;
              }
            },
            { field: 'amount', headerName: 'Total Cost', width: 140, cellClass: 'text-center', valueFormatter: this.currencyFormatter },
          ],
        },
        getDetailRowData: function (params) {
          params.successCallback(params.data.requirement_items);
        },
      },
    });

    this.gridConnect();
  }

  disconnect() {
    this.gridDisconnect();
    this.subscription.unsubscribe();
  }

  currencyFormatter = (params) => {
    return '$' + Math.floor(params.value)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };

  shouldShowTopN = (params) => {
    return (
            this.organizationLevelValue === 'MAJCOM' ||
            this.organizationLevelValue === 'NAF' ||
            this.organizationLevelValue === 'Wing'
           ) &&
           this.isUnfilteredAndUnsorted(params)
  }

  shouldShowOwnerNumber = () => {
    return this.ownerTypeValue === 'drill'
  }

  shouldShowWeightedScore = () => {
    return this.ownerTypeValue === 'drill'
  }

  shouldShowRollUp = (params) =>  {
    return (
             this.ownerTypeValue === 'drill' ||
             this.organizationLevelValue === 'MAJCOM' ||
             this.organizationLevelValue === 'NAF' ||
             this.organizationLevelValue === 'Wing'
           )
  }

  isUnfilteredAndUnsorted = (params) => {
    return !params.api.isColumnFilterPresent() &&
           !params.columnApi.getColumnState().find(c => c.sort);
  }

  updateGridNumbers(data) {
    data.forEach(e => {
      const node = this.gridOptions.api.getRowNode(e.id);
      if (node.data.number !== e.number) {
        node.setDataValue('number', e.number);
        setTimeout(() => {
          this.gridOptions.api.applyTransactionAsync({
            remove: [node]
          });
          this.gridOptions.api.applyTransactionAsync({
            add: [Object.assign({}, node.data, e)],
            addIndex: parseInt(e.number) - 1
          });
        }, 800);
      }
    });
  }

  rollUp(params) {
    const ids = [];
    params.api.getSelectedNodes().forEach((node) => {
      ids.push(node.data.id)
    });
    this.subscription.perform("roll_up", { ids })
  }

  breakOut(params) {
    const ids = [];
    params.api.getSelectedNodes().forEach((node) => {
      ids.push(node.data.id)
    });
    this.subscription.perform("break_out", { ids })
  }

}
